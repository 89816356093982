#straight-line {
	position: fixed;
	display: block;
	width: 50%;
	height: 5%;
	top: 0;
	left: 0;
	border-right: 1px solid rgba(0, 0, 0, 0.3);
	color: black;
	z-index: 1;
	pointer-events: none;
}

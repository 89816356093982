.event-section {
	padding-top: 4%;
	overflow-x: hidden;
	/* position: relative;
	z-index: 2; */
}
.event-title {
	font-size: 1.5rem;
	font-weight: 700;
	padding-bottom: 2%;
	font-family: "Libre Baskerville", serif;
}

.event-image-container {
	display: block;
	overflow: hidden;
}

.event-img {
	width: 100%;
	height: 350px !important;
	object-fit: cover;
	object-position: 0% 20%;
	transform: scale(1);
	transition: all 0.25s ease-out;
}

.event-links {
	text-decoration: none;
}

.arrows {
	width: fit-content;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
}

.carousel-left-arrow,
.carousel-right-arrow {
	cursor: pointer;
	border: 1px solid #f4f4f4;
	width: 30px;
	height: 30px;
	font-size: 20px;
	text-align: center;
}

.carousel-left-arrow.active,
.carousel-right-arrow.active,
.carousel-left-arrow:hover,
.carousel-right-arrow:hover {
	color: white;
	background-color: #b32825;
}

.event-carousel {
	opacity: 0;
	transition-duration: 2s ease;
}

.event-carousel.active {
	opacity: 1;
	transition-duration: 2s;
}
.event-subtile {
	font-size: 1.2rem;
	width: 75%;
	font-weight: 500;
	padding-bottom: 2%;
	text-transform: capitalize;
	font-family: "Merriweather", serif;
	font-weight: 600;
}

.event-descrp {
	width: 95%;
	line-height: 2;
	font-family: "Merriweather", serif;
}

.event-date {
	display: flex;
	justify-content: space-evenly;
	border-right: 3.5px solid #191919;
}

.event-section .row:nth-of-type(2) {
	position: relative;
	background-color: white;
	z-index: 1;
	padding-bottom: 4%;
}
.dates-container {
	position: relative;
	z-index: 2;
}

.header-spotlight,
.header-online {
	font-family: "Libre Baskerville", serif;
	font-size: 1.5rem;
	font-weight: 700;
}

.event-subheader {
	width: fit-content;
	background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
	background-size: 0% 1.5px;
	background-position: 0% 100%;
	background-repeat: no-repeat;
	transition: background 0.5s linear;
}

@media (min-width: 1200px) {
	.hovered-event-img {
		transform: scale(1.1) !important;
	}
	.hovered-event-header {
		background-size: 100% 1.5px !important;
	}
}

@media screen and (max-width: 990px) {
	.event-section {
		position: relative;
		z-index: 2;
		background-color: whitesmoke;
	}
	.event-img {
		height: 280px !important;
	}
}

#feature {
  background: url("../../assets/images/cpu.jpg");
  background-position: 50% 94px;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 100px 0;
}
#feature h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}
#feature p {
  color: #8d8f92;
  margin-bottom: 20px;
}
#feature .btn-view-works {
  background: #31323b;
  color: #fff;
  padding: 10px 20px;
}

.btn-view-works:hover {
  background: transparent !important;
  border: #31323b 1px solid;
  color: #31323b !important;
}

.mission {
  flex-direction: row-reverse;
}

@font-face {
	font-family: "University";
	src: url("../../assets/fonts/University/UniversityRegular.ttf");
	src: url("../../assets/fonts/University/UniversityRegular.ttf")
			format("truetype"),
		url("../../assets/fonts/University/UniversityRegular.eot")
			format("embedded-opentype"),
		url("../../assets/fonts/University/UniversityRegular.woff") format("woff");
}
.hero-area {
	position: relative;
	z-index: 2;
}

#home.hero-area {
	height: calc(100vh - 80px);
	overflow: hidden;
}

.home-wrapper {
	position: absolute;
	top: 45%;
	left: 0%;
	right: 0;
}

.hero-area::before,
.hero-area::after {
	content: "";
	position: absolute;
	top: 0;
	width: 50%;
	left: 0;
	border-right: 1px solid rgba(0, 0, 0, 0.3);
	z-index: 1;
}

.hero-area::before {
	height: 0;
	animation-name: lineDown;
	animation-duration: 3s;
	animation-delay: 0s;
	animation-fill-mode: forwards;
	/* margin-top: 460px; */
}

.hero-area::after {
	height: 0;
	animation-name: lineDown;
	animation-duration: 3s;
	animation-delay: 2.5s;
	animation-fill-mode: forwards;
	margin-top: 460px;
}

@keyframes lineDown {
	from {
		height: 0;
	}
	to {
		height: 35vh;
	}
}

.home-wrapper .col-md-12 {
	position: relative;
	z-index: 3;
}
.home-wrapper h1 {
	position: relative;
	z-index: 3;
	/* padding: 50px 0; */
	/* height: 300px; */
	/* background-color: grey; */
}

.bg-image {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-position: center;
	background-size: cover;
}

.bg-image.bg-parallax {
	background-attachment: fixed;
}

.bg-image.overlay:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-image: -webkit-linear-gradient(
		linear,
		left top,
		left bottom,
		from(#374050),
		to(#798696)
	);
	background-image: linear-gradient(to bottom, #374050 0%, #798696 100%);
	opacity: 0.8;
}

.hero-area-tree li {
	display: inline-block;
	font-family: "Lato", sans-serif;
	font-weight: 600;
	font-size: 1rem;
	color: rgba(255, 255, 255, 0.8);
}

.hero-area-tree li > a {
	color: rgba(255, 255, 255, 0.8);
}

.hero-area-tree li + li:before {
	content: "/";
	display: inline-block;
	margin: 0px 5px;
	color: rgba(235, 235, 235, 0.25);
}

.hero-img {
	background-image: url("../../assets/images/login.jpeg");
	background-size: auto;
}

.hero-text {
	color: #fff;
	font-size: 5rem;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-family: "University";
}

@media (max-width: 1200px) {
	.hero-text {
		font-size: 3.5rem;
		/* text-align: center; */
		margin: auto;
		/* padding: 0 10%; */
		width: 70%;
	}
}
@media (max-width: 800px) {
	.hero-text {
		font-size: 3.5rem;
		width: 100%;
	}
}

.dev-event {
  margin-bottom: 30px;
}
.dev-event .dev-thumb {
  overflow: hidden;
}
.dev-event .dev-thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 31rem;
  width: 100%;
  transform: scale(1);
}

.dev-event .dev-event-details {
  background: rgba(0, 35, 71, 0.5);
  position: absolute;
  top: 0px;
  right: 0px;
  width: 275px;
  padding: 30px 25px;
  color: #ffffff;
}
.dev-event .dev-event-details .dev-event-date {
  color: #ffffff;
  padding-right: 15px;
  border-right: 2px solid #fff;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
}
.dev-event .dev-event-details .dev-event-date span {
  display: block;
  color: #fdc632;
  font-size: 28px;
  font-weight: 500;
}
.dev-event .dev-event-details .dev-event-time-location {
  padding-left: 15px;
  font-size: 14px;
}
.dev-event .dev-event-details .dev-event-time-location p {
  margin-bottom: 0px;
}
.dev-event .dev-event-details .dev-event-time-location p span {
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
}

.dev-event :hover h4 a {
  color: #fdc632;
}

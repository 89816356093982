
#free-trial-block {
	padding: 70px 0 55px;
	background: #31313b;
}
#free-trial-block .row {
	padding: 15px;
}
#free-trial-block p {
	font-size: 24px;
	font-weight: 500;
	color: #fff;
	margin-bottom: 0;
	display: inline-block;
	float: left;
	max-width: 800px;
}
#free-trial-block a {
	float: right;
	max-width: 14rem;
	display: inline-block;
	color: black;
	border-radius: 5px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 400;
	background: whitesmoke;
	padding: 2% 4%;
	letter-spacing: 0.8px;
	text-decoration: none;
}
#free-trial-block a:hover {
	border: 1px #fff solid;
	background: none;
	color: white;
	text-decoration: none;
	transition: all .6s;
	-moz-transition: all .6s;
	-webkit-transition: all .6s;
}
@font-face {
  font-family: Photograph Signature;
  src: url("../../assets/fonts/Photograph/Photograph\ Signature.ttf");
}
.team-card-body {
  background: #d9d9d9;
}

.title-on-top {
  padding-top: 2%;
  text-transform: none;
  font-size: 30px;
  text-align: center;
}

.team-card-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #112e35;
  height: fit-content;
  color: #fff;
  margin-top: 2%;
  padding-bottom: 10%;
}
.team-card {
  margin-left: 1%;
  padding-top: 1%;
  width: 47%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
}
.title {
  text-align: center;
}
.logo {
  text-align: right;
  width: 100%;
  margin-bottom: -20px;
}
.logo img {
  width: 100px;
  height: 100px;
}
.team-card p {
  padding: 250px 20px 20px 20px;
  text-align: center;
  font-size: 12px;
  color: black;
  background-color: #faf7f3;
  font-family: "Carme";
  text-transform: none;
  letter-spacing: normal;
  width: 500px;
  height: fit-content;
  margin-top: -240px;
  z-index: 0;
}
.teamcard-img {
  margin-top: 2%;
  background: linear-gradient(to right, #cab393, #a96b38);
  box-shadow: 0 0 5px #cab393;
  z-index: 1;
}
.teamcard-img img {
  padding: 1%;
  width: 400px;
  height: 300px;
  object-fit: cover;
}
.teammember-name {
  text-transform: capitalize;
  font-family: Photograph Signature;
  color: #cab393;
  font-size: 80px;
  letter-spacing: 7px;
  transform: rotate(-10deg);
  margin-top: -40px;
  z-index: 1;
}

@media (max-width: 1120px) {
  .team-card p {
    width: 400px;
  }
}

@media (max-width: 900px) {
  .team-card-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .team-card p {
    width: 500px;
  }
  .logo {
    width: 500px;
  }
}

@media (max-width: 500px) {
  .teamcard-img img {
    width: 100%;
    height: 300px;
  }
  .logo {
    width: 100%;
  }
  .team-card p {
    width: 100%;
  }
  .team-card {
    width: 95%;
  }
}

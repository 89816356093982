.quote-section {
	background-color: whitesmoke;
	/* padding: 4% 15%; */
}

.feature-one {
	display: flex;
	justify-content: space-evenly;
	padding-top: 8%;
	padding-bottom: 12%;
}

.feature-one > div {
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
}

.feature-one-caption {
	width: 80%;
	font-size: 13px;
	font-style: italic;
	padding-top: 2%;
	font-family: "Carme", sans-serif;
}

.feature-one div p {
	position: relative;
	width: 100%;
}

.feature-one div:first-child img {
	height: 32rem;
	width: 85%;
	object-fit: cover;
}

.quote-info {
	font-size: 2.5rem;
	font-weight: 600;
	font-family: "Libre Baskerville", serif;
	position: relative;
	z-index: 2;
}

.quote-info-contact {
	font-size: 1.5rem;
	font-family: "Carme", sans-serif;
}

.quote-container {
	padding-left: 50px !important;
}

.quote-container::before {
	content: "\201C";
	font-size: 22rem;
	line-height: 1;
	position: absolute;
	top: -13%;
	left: 5%;
	z-index: 1;
	font-family: sans-serif, serif;
	color: whitesmoke;
	background-color: whitesmoke;
	-webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.3);
	height: 25%;
}

.feature-one div:last-child {
	align-self: auto;
}

.feature-one div:last-child div a {
	padding-left: 4%;
	font-family: "Carme", sans-serif;
}

.quote-link {
	font-size: 1.3rem;
	color: black;
}

.quote-btn {
	margin-top: 5%;
	border-radius: 50%;
	width: 4rem;
	padding: 1%;
	font-size: 2rem;
	color: white;
	background: gray;
	border: none;
}
@media (max-width: 1200px) {
	.feature-one div:first-child img {
		width: 90%;
	}
	.quote-info {
		font-size: 2rem;
		padding: 4%;
	}

	.quote-container {
		padding: 0 !important;
	}
	.quote-info-contact {
		font-size: 1.5rem;
		padding: 4%;
	}
	.quote-link {
		font-size: 1rem;
		padding: 4%;
	}
	.quote-btn {
		width: 3rem;
		font-size: 1.5rem;
	}
	.feature-one-caption {
		width: 90%;
		padding-top: 2%;
		padding-left: 0px;
		white-space: pre-line;
	}
}
@media (max-width: 1000px) {
	.quote-info {
		font-size: 1.5rem;
		padding: 4%;
	}
	.quote-info-contact {
		font-size: 1.4rem;
		padding: 4%;
	}
	.quote-link {
		font-size: 0.8rem;
		padding: 4%;
	}
	.quote-btn {
		width: 2.1rem;
		font-size: 1.2rem;
	}
	.feature-one div:first-child img {
		height: 26rem;
		width: 100%;
	}
	.feature-one div:last-child p:first-child::before {
		font-size: 10rem;
		top: -3%;
		left: -4%;
	}
}

@media (max-width: 769px) {
	.feature-one {
		position: relative;
		z-index: 2;
		background-color: whitesmoke;
		width: unset;
	}

	.quote-container {
		padding-top: 20px !important;
	}

	.quote-container::before {
		left: 0%;
		top: -5%;
		font-size: 15rem;
		background-color: transparent;
		padding-top: 10px !important;
	}

	.feature-one div:first-child {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		/* width: 90%; */
	}

	.feature-one div:first-child img {
		height: 30rem;
		/* width: 95%; */
		padding-bottom: 5%;
	}

	.feature-one div span {
		justify-content: start;
	}
	.quote-link {
		font-size: 1rem;
		padding: 4%;
	}
	.quote-btn {
		width: 2.5rem;
		font-size: 1.3rem;
	}
	.feature-one-caption {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		z-index: 3;
		position: relative;
	}
}

@font-face {
  font-family: "Mermaid";
  src: url("../../../assets/fonts/Mermaid/Mermaid-Bold.ttf");
  src: url("../../../assets/fonts/Mermaid/Mermaid-Bold.ttf") format("truetype"),
    url("../../../assets/fonts/Mermaid/Mermaid1001.eot")
      format("embedded-opentype"),
    url("../../../assets/fonts/Mermaid/Mermaid-Bold.woff") format("woff");
}

.latest-and-trending-container {
  width: 80%;
  height: fit-content;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 10%;
}

.tab {
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

.tab button.active {
  border-bottom: 2px solid #aa0600;
}

.tabcontent {
  display: none;
  border: none;
  width: 100%;
}

.tabcontent li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(128, 128, 128, 0.158);
  padding-top: 2%;
}

.tabcontent ul div:first-child {
  font-size: 20px;
}

.tabcontent-category {
  color: #d67113;
  text-transform: uppercase;
  font-weight: 600;
}

.list-items {
  margin-left: 15%;
}

.list-items h6 {
  font-size: 38px;
  width: 85%;
  font-family: "Mermaid ", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.list-items p {
  font-size: 14px;
  width: 85%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-height: 2rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  font-family: "Mermaid ", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.list-items h6:hover {
  color: #aa0600;
  transition: 1s;
  cursor: pointer;
}

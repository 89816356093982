@font-face {
  font-family: "University";
  src: url("../../assets/fonts/University/UniversityRegular.ttf");
  src: url("../../assets/fonts/University/UniversityRegular.ttf")
      format("truetype"),
    url("../../assets/fonts/University/UniversityRegular.eot")
      format("embedded-opentype"),
    url("../../assets/fonts/University/UniversityRegular.woff") format("woff");
}

.comingsoon {
  margin: 0;
  background: #000;
  background-size: cover;
  height: 100vh;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

div#univercity {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comingsoon-header {
  position: fixed;
  width: 70%;
  text-align: center;
  color: white;
  transition: 0.4s;
  top: 2%;
  left: 15%;
}

.comingsoon-title {
  font-size: 6rem;
  width: 100%;
  letter-spacing: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.7;
  mix-blend-mode: overlay !important;
  color: #fff;
  line-height: 1.2;
  font-weight: 100;
  font-family: "University";
}
.comingsoon-link {
  text-decoration: none;
  color: white;
  padding: 1rem;
  font-size: 2rem;
  position: absolute;
  top: 90%;
  right: 10%;
}

#univercity h2 {
  font-family: "Noto Serif", serif;
  font-size: 5rem;
  text-align: center;
  margin: 2rem 3rem 0;
  mix-blend-mode: overlay;
  color: #fff;
  font-weight: 100;
}

.services-section {
	/* padding: 0 4%; */
	position: relative;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
}

.services-cont {
	margin: 0 !important;
	padding: 0 !important;
}
.carousel-root {
	position: relative;
	z-index: 2;
	background-color: whitesmoke;
}

.carousel .slide {
	opacity: 0.5;
	transition: all 0.5s;
	user-select: none;
}

.carousel .slide:first-of-type {
	transform: translateX(5%);
	padding: 0 5%;
}

.carousel .slide:nth-of-type(2) {
	padding: 0 5%;
}

.carousel .slide:last-of-type {
	transform: translateX(-5%);
	padding: 0 5%;
}

.carousel .slide.selected {
	opacity: 1;
}

.services-item {
	margin-bottom: 50px;
	display: flex;
	font-family: "Merriweather", serif;
}

.services-item div {
	width: 50%;
}
.services-info {
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 50px;
}

.services-info h5 {
	font-size: 2.5rem;
}

.services-info .services-link {
	text-decoration: none;
	font-weight: 600;
	padding-top: 4%;
}

.service-title {
	font-size: 3.5rem;
	font-weight: 700;
	font-family: "Libre Baskerville", serif;
	margin: 0;
}

.service-subtitle {
	font-size: 1.5rem;
	padding-top: 1%;
	width: 50%;
	margin: 0;
	font-weight: 500;
}

.services-section .mt-2 {
	margin-top: 0 !important;
}

.enroll-section {
	position: relative;
	padding-top: 90px;
	padding-bottom: 90px;
	z-index: 2;
}

.enroll-section:after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #000;
	opacity: 0.8;
}

.enroll-section .container {
	position: relative;
	z-index: 1;
}

.enroll-section .section-title {
	margin-bottom: 40px;
	font-family: "Libre Baskerville", serif;
}

.section-title h3 {
	font-size: 2.5rem;
	position: relative;
}

.enroll-list-item {
	padding-left: 70px;
	position: relative;
	padding-bottom: 15px;
	margin-bottom: 10px;
}

.enroll-list-item p {
	font-family: "Merriweather", serif;
}

.enroll-list-item span {
	position: absolute;
	width: 40px;
	height: 40px;
	left: 0;
	top: 0;
	text-align: center;
	border-radius: 50px;
	background: #c33b4c;
	font-size: 1rem;
	padding-top: 10px;
}

.enroll-list-item h5 {
	margin-bottom: 10px;
}

.enroll-list-item:after {
	position: absolute;
	content: "";
	height: calc(100% - 45px);
	left: 20px;
	top: 48px;
	border-left: 1px dashed #6c6e70;
	opacity: 0.8;
}

.enroll-list-item:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

.enroll-list-item:last-child:after {
	display: none;
}

.enroll-list-item p {
	display: inline;
	background-image: linear-gradient(
		rgba(255, 255, 255, 0.9),
		rgba(255, 255, 255, 0.9)
	);
	background-size: 0% 1px;
	background-position: 0% 100%;
	background-repeat: no-repeat;
	transition: background 0.5s linear;
}
.enroll-list-item p:hover {
	background-size: 100% 1px;
}

.set-bg {
	background: url("../../assets/images/educationalCon.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
}
.section-title p {
	color: #6f6f6f;
	font-size: 16px;
	max-width: 800px;
	margin: 0 auto;
	font-family: "PT Serif", serif;
	margin-top: 50px;
}
.text-white {
	color: #fff;
	text-transform: capitalize;
}

.enrollment-img {
	width: 100%;
	height: 80%;
}

.services-img {
	max-width: 100%;
	width: auto;
	max-height: 30rem;
}

.services-header-cont {
	position: relative;
	z-index: 2;
	background-color: whitesmoke;
	overflow: hidden;
	padding: 4% 0;
}

.services-header {
	position: relative;
	z-index: 2;
	background-color: whitesmoke;
	overflow: hidden;
}
.services-info .services-link {
	text-decoration: none;
	font-weight: 600;
	padding-top: 4%;
	width: fit-content;
}

.control-arrow {
	width: 10% !important;
}

@media screen and (max-width: 800px) {
	.service-title {
		font-size: 2.5rem;
	}
	.service-subtitle {
		font-size: 1rem;
	}
	.services-item {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.services-item div {
		width: 80%;
		margin: auto;
	}
	.services-item div:first-of-type {
		margin-bottom: 5%;
	}
}
@media screen and (max-width: 690px) {
	.enroll-list-item span {
		width: auto;
		height: auto;
		border-radius: 50%;
		padding: 25px;
	}
}

#slider {
  background: url("../../assets/images/test.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 10% 0%;
  padding: 200px 0 280px 0;
  position: relative;
}
#slider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #8b86a3, #322e40);
  opacity: 0.8;
}
#slider .block {
  color: #e3e3e4;
}
#slider .block h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 45px;
  line-height: 60px;
  letter-spacing: 10px;
  padding-bottom: 45px;
  color: white;
}
#slider .block p {
  font-size: 23px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
}

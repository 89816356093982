#process-block {
  padding: 80px 0 160px;
}
#process-block .nav-tabs {
  text-align: center;
  border-bottom: 0px #bcc6d0 solid;
}
#process-block .nav-tabs:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #bcc6d0;
  position: relative;
  top: 58px;
}
#process-block .nav-tabs > li {
  float: none;
  display: inline-block;
  margin: 0 auto;
}
#process-block .nav-tabs > li {
  border-bottom: 0px #bcc6d0 solid;
  border: none;
  padding: 10px 35px;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
#process-block .nav-tabs > li.active,
#process-block .nav-tabs > li.active:hover,
#process-block .nav-tabs > li.active:focus {
  border-bottom: 0px #bcc6d0 solid;
  background-color: transparent;
}
#process-block .nav-tabs > li.active i {
  color: #ff5959;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
#process-block .nav-tabs > li.active i:after {
  background: #ff5959;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
#process-block .nav > li:hover,
#process-block .nav > li:focus {
  background-color: transparent;
  color: #ff5959;
}
#process-block .nav > li:hover i {
  color: #ff5959;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
#process-block .nav > li:hover i:after {
  background: #ff5959;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
#process-block .nav-tabs > li i {
  color: #bcc6d0;
  font-weight: 400;
  font-size: 24px;
  display: block;
}
#process-block .nav-tabs > li i:after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: relative;
  background: #bcc6d0;
  top: 30px;
  right: 14px;
}
#process-block .nav-tabs > li h5 {
  color: #404040;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin-top: 50px;
}
#process-block .tab-content .tab-pane {
  background: none;
  margin-top: 120px;
  display: inline-flex;
}
#process-block .process-img img {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  max-width: 360px;
  margin: 0 auto;
  min-height: 400px;
}
#process-block .process-content {
  padding: 5em 3em;
}
#process-block .process-content h6 {
  font-size: 42px;
  color: #3c434d;
  font-weight: 600;
}
#process-block .process-content p {
  color: #6f6f6f;
  font-size: 16px;
  font-weight: 400;
  font-family: "PT Serif", serif;
  margin-top: 50px;
  margin-bottom: 35px;
}
#process-block .process-content a {
  color: #e95151;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
#process-block .process-content a:hover {
  color: #404040;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}

.section-title p {
  color: #6f6f6f;
  font-size: 16px;
  max-width: 800px;
  margin: 0 auto;
  font-family: "PT Serif", serif;
  margin-top: 50px;
}
.section-title a {
  /*position:relative; margin-top:-64px; margin-right:8px;*/
  float: right;
  padding: 12px 50px;
  font-size: 11px;
  line-height: 1.42857143;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  background-color: transparent;
  border: 2px #d9d9d9 solid;
  color: #404040;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.8px;
  margin-top: -43px;
  text-align: right;
}
.section-title a:hover {
  background-color: #ff4a5b;
  border: 2px #ff4a5b solid;
  color: #fff;
  transition: all 0.6s;
  -moz-transition: all 0.6s;
  -webkit-transition: all 0.6s;
  text-decoration: none;
}
.section-title a {
  display: block;
  float: none;
  text-align: center;
  margin-top: 50px;
}

.register {
  background: -webkit-linear-gradient(left, #3931af, #00c6ff);
  background-image: url('../../assets/images/login.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
}

.register div {
  padding-right: 2%;
  padding-left: 2%;
}

.register-left {
  height: 500px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  text-align: center;
}

.register-left h3 {
  color: white;
  font-size: 2rem;
}

.register-right {
  height: 500px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: #fff;
}
.register-left img {
  width: 70%;
}

.register-left h6 {
  padding: 12%;
  width: 90%;
  line-height: 1.5;
  font-size: 1.3rem;
}
.register .register-form {
  padding: 10%;
  margin-top: 10%;
  width: 100% !important;
}

.btnRegister {
  float: right;
  margin-top: 4%;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #aa0401;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}
.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #aa0401;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}
.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover {
  border: none;
}
.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #aa0401;
  border: 2px solid #aa0401;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #fff;
}

.checkboxGroup {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.checkboxGroupTitle {
  text-align: center;
}

.footer {
	background-color: rgba(0, 0, 0);
	padding: 2%;
	color: white !important;
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	backdrop-filter: saturate(180%) blur(20px);
	position: relative;
	z-index: 2;
}

.link-secondary {
	text-decoration: none;
}

.social {
	display: flex;
	justify-content: center;
}

.social li svg {
	color: white;
	font-size: 2.5rem;
}

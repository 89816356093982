.admission_area {
  position: relative;
  padding: 110px 0;
  z-index: 2;
}

.admission_area::before {
  position: absolute;
  background-image: url("../../assets/images/grad.jpg");
  right: 0;
  content: "";
  width: 60%;
  top: 0;
  bottom: 0;
  z-index: -2;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 767px) {
  .admission_area::before {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .admission_area::before {
    width: 100%;
  }
}

.admission_area::after {
  position: absolute;
  right: 0;
  content: "";
  width: 60%;
  top: 0;
  bottom: 0;
  z-index: -1;
  /* background-color: rgba(23, 47, 72, 0.871); */
  /* background: linear-gradient(to right, rgba(189, 63, 50, 0.871) 0%, rgba(203, 53, 107, 0.871) 100%); */
  background: #000;
  opacity: 0.8;
}

@media (max-width: 767px) {
  .admission_area::after {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .admission_area::after {
    width: 100%;
  }
}

.admission_area .admission_inner::before {
  position: absolute;
  left: 0;
  width: 40%;
  top: 0;
  bottom: 0;
  content: "";
  background-size: cover;
  background-position: center center;
  background-image: url("https://i.imgur.com/Jamvs9k.jpg");
}

@media (max-width: 767px) {
  .admission_area .admission_inner::before {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .admission_area .admission_inner::before {
    display: none;
  }
}

.admission_area .admission_form h3 {
  color: #fff;
  font-size: 3.5rem;
  margin-bottom: 40px;
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
}

.admission_area .admission_form .single_input input,
.admission_area .admission_form .single_input textarea {
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #7b7e8d;
  padding-bottom: 15px;
  margin-bottom: 40px;
}

.admission_area .admission_form .single_input input::placeholder,
.admission_area .admission_form .single_input textarea::placeholder {
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
}

.admission_area .admission_form .single_input input:focus,
.admission_area .admission_form .single_input textarea:focus {
  outline: none;
}

.admission_area .admission_form .single_input textarea {
  height: 110px;
}

.admission-button {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  background-color: #c33b4c;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  text-transform: capitalize;
  font-weight: 700;
}

.admission-button:hover,
.admission-button:focus {
  background-color: #fff;
  border: 2px solid #c33b4c;
  color: #c33b4c;
}

.white:hover {
  background-color: #c33b4c;
  border: 2px solid #fff;
  color: #fff;
}
.white {
  background-color: #fff;
  color: #c33b4c;
  text-decoration: none !important;
}

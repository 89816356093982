@font-face {
  font-family: "LemonMilk";
  src: url("../../../assets/fonts/LemonMilk/LEMONMILK-Regular.ttf");
  src: url("../../../assets/fonts/LemonMilk/LEMONMILK-Regular.ttf")
      format("truetype"),
    url("../../../assets/fonts/LemonMilk/LEMONMILK-Regular.eot")
      format("embedded-opentype"),
    url("../../../assets/fonts/LemonMilk/LEMONMILK-Regular.woff") format("woff");
}
@font-face {
  font-family: "Mermaid";
  src: url("../../../assets/fonts/Mermaid/Mermaid-Bold.ttf");
  src: url("../../../assets/fonts/Mermaid/Mermaid-Bold.ttf") format("truetype"),
    url("../../../assets/fonts/Mermaid/Mermaid1001.eot")
      format("embedded-opentype"),
    url("../../../assets/fonts/Mermaid/Mermaid-Bold.woff") format("woff");
}

.report-container {
  height: fit-content;
  width: 85%;
  margin: auto;
}

.title-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
}

.title-logo-container img {
  /* position: absolute;
    left: 20px; */
}

.title-logo-container span {
  display: flex;
  padding-left: 8%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 75px;
  font-family: "LemonMilk", Verdana, Geneva, Tahoma, sans-serif;
}
.title-logo-container span .title-target {
  color: #d67113;
}

.news-section {
  padding-left: 1%;
  display: flex;
  justify-content: center;
}

.main-news {
  width: 60%;
}

.latest-news {
  width: 30%;
  padding-left: 1%;
  font-family: "Mermaid", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.latest-news div:first-child {
  height: 10%;
  padding-left: 32px;
  padding-top: 2%;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Mermaid ", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.latest-news a {
  text-decoration: none;
  color: black;
}

.latest-news a:hover {
  transition: 1s;
  color: #aa0600;
}

.news-section img {
  width: 100%;
}

.news-section ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.news-section li {
  height: fit-content;
  padding-top: 4%;
  padding-bottom: 4%;
  width: 65%;
}

.news-section li::after {
  margin-top: 10px;
  content: "";
  display: block;
  height: 2px;
  width: 6%;
  background: rgba(128, 128, 128, 0.199);
  position: absolute;
}

.news-text {
  margin-top: 1%;
  width: 100%;
  font-family: "Mermaid", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.news-text h5 {
  font-size: 32px;
}

.news-text span {
  font-size: 16px;
}

@media screen and (max-width: 990px) {
  .title-logo-container span {
    font-size: 40px;
    padding-left: 5%;
  }
}

@media screen and (max-width: 730px) {
  .title-logo-container span {
    padding-left: 0;
  }

  .news-section li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
  }

  .news-section img {
    width: 100%;
    height: 200px;
  }
}

@media screen and (max-width: 500px) {
  .title-logo-container img {
    width: 50%;
    height: 50%;
  }

  .title-logo-container span {
    font-size: 30px;
  }
}
